import { useEffect } from "react";
import {
  createBrowserRouter,
  generatePath,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  EndpointTabValue,
  PageRoute,
  UsageBillingTabValue,
} from "src/utility/utils";
import { Login } from "src/ui/pages/login";
import { Dashboard } from "src/ui/pages/dashboard";
import { SelectChain } from "src/ui/pages/select-chain";
import { SelectNetwork } from "src/ui/pages/select-network";
import { Urls } from "src/ui/pages/endpoint_page/urls";
import { Metrics } from "src/ui/pages/endpoint_page/metrics";
import { Usage } from "src/ui/pages/usage_billing_page/usage";
import { Plan } from "src/ui/pages/usage_billing_page/plan";
import { History } from "src/ui/pages/usage_billing_page/history";
import { PaymentMethods } from "src/ui/pages/usage_billing_page/payment-methods";
import { Register } from "src/ui/pages/register";
import { ApiKeysPage } from "src/ui/pages/api_keys";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import { Logout } from "src/ui/pages/logout";
import { Return } from "src/ui/components/stripe";
import { AxiosInterceptorsSetup } from "src/api/marly";
import { EndpointsPage } from "src/ui/pages/endpoints";
import { urlify } from "src/utility/endpoint";
import { VerifyPage } from "src/ui/pages/verify";
import { useUser } from "src/store/user";
import { PasswordResetPage } from "src/ui/pages/login/password_reset";
import { ForgotPasswordPage } from "src/ui/pages/login/forgot-password";
import { DedicatedNodes } from "src/ui/pages/dedicated_nodes";
import { Settings } from "src/ui/pages/settings";

export const usagePath = generatePath(PageRoute.USAGE_BILLING_TABS, {
  tabValue: UsageBillingTabValue.USAGE,
});
export const billingPath = generatePath(PageRoute.USAGE_BILLING_TABS, {
  tabValue: UsageBillingTabValue.BILLING,
});
export const historyPath = generatePath(PageRoute.USAGE_BILLING_TABS, {
  tabValue: UsageBillingTabValue.HISTORY,
});
export const paymentMethodsPath = generatePath(PageRoute.USAGE_BILLING_TABS, {
  tabValue: UsageBillingTabValue.PAYMENT_METHODS,
});

export const endpointGetStartedPath = (chainName: string) =>
  generatePath(PageRoute.ENDPOINT_TABS, {
    tabValue: EndpointTabValue.GET_STARTED,
    id: urlify(chainName),
  });

export const endpointGetMetricsPath = (chainName: string) =>
  generatePath(PageRoute.ENDPOINT_TABS, {
    tabValue: EndpointTabValue.METRICS,
    id: urlify(chainName),
  });

type ProtectedRouteProps = {
  children: React.ReactNode;
};

export const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const location = document.location;
    const query = new URLSearchParams();
    query.set("from", location.pathname + location.search);

    const navigateFun = () => {
      // We must check against document.location.pathname here to ensure that we
      // get the current location. the useLocation location will be captured and
      // kept static in the closure which causes a logout <-> login loop if
      // the logout call fails on the logout page.
      if (document.location.pathname !== PageRoute.LOGOUT) {
        navigate(PageRoute.LOGOUT + `?${query.toString()}`, {
          replace: true,
        });
      }
    };
    AxiosInterceptorsSetup(navigateFun);
  }, [navigate]);

  return <></>;
};

// Protected Route Wrapper
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [cookies] = useCookies(cookieSelector);
  const location = useLocation();
  const user = useUser();

  if (isAuthenticated(cookies) === false) {
    if (location.pathname === PageRoute.LOGOUT) {
      // Don't create a Login -> Logout -> Login redirect loop
      return <Navigate to={PageRoute.LOGIN} replace />;
    }
    return <Navigate to={PageRoute.LOGIN} replace state={{ from: location }} />;
  }

  if (
    user.state === "fulfilled" &&
    user.data.isVerified === false &&
    location.pathname !== PageRoute.VERIFY &&
    // Until we can send emails to anyone we want, we'll disable this feature
    process.env.REACT_APP_VALIDATE_EMAIL === "true"
  ) {
    return <Navigate to={PageRoute.VERIFY} replace />;
  }

  return (
    <>
      {/* If we ever encounter a 401 error this interceptor will redirect us to
      the login page before continuing. */}
      <AxiosInterceptorNavigate />
      {children}
    </>
  );
};

export const router = createBrowserRouter(
  [
    {
      path: PageRoute.INDEX,
      element: (
        <ProtectedRoute>
          <Navigate to={"dashboard"} />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: PageRoute.LOGIN,
      element: <Login />,
    },
    {
      path: PageRoute.LOGOUT,
      element: <Logout />,
    },
    {
      path: PageRoute.FORGOT_PASSWORD,
      element: <ForgotPasswordPage />,
    },
    {
      path: PageRoute.RESET_PASSWORD,
      element: <PasswordResetPage />,
    },
    {
      path: PageRoute.DASHBOARD,
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.ENDPOINTS,
      element: (
        <ProtectedRoute>
          <EndpointsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.CHECKOUT_RETURN,
      element: (
        <ProtectedRoute>
          <Return />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.ENDPOINT + "/urls",
      element: (
        <ProtectedRoute>
          <Urls />,
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.ENDPOINT + "/metrics",
      element: (
        <ProtectedRoute>
          <Metrics />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.SELECT_CHAIN,
      element: (
        <ProtectedRoute>
          <SelectChain />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.SELECT_NETWORK + "/:chainId",
      element: (
        <ProtectedRoute>
          <SelectNetwork />
        </ProtectedRoute>
      ),
    },
    {
      path: usagePath,
      element: (
        <ProtectedRoute>
          <Usage />
        </ProtectedRoute>
      ),
    },
    {
      path: billingPath,
      element: (
        <ProtectedRoute>
          <Plan />
        </ProtectedRoute>
      ),
    },
    {
      path: historyPath,
      element: (
        <ProtectedRoute>
          <History />
        </ProtectedRoute>
      ),
    },
    {
      path: paymentMethodsPath,
      element: (
        <ProtectedRoute>
          <PaymentMethods />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.SETTINGS,
      element: (
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.API_KEYS,
      element: (
        <ProtectedRoute>
          <ApiKeysPage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.VERIFY,
      element: (
        <ProtectedRoute>
          <VerifyPage />
        </ProtectedRoute>
      ),
    },
    {
      path: PageRoute.REGISTER,
      element: <Register />,
    },
    {
      path: PageRoute.DEDICATED_NODES,
      element: (
        <ProtectedRoute>
          <DedicatedNodes />
        </ProtectedRoute>
      ),
    },
  ],
  { basename: getEnvironmentSpecificBasename(window.location.pathname) },
);

// This function is used to determine the basename of the router based on the environment
// If we're running in a subfolder, we need to set the basename to that subfolder
// Currently we have one environment that uses a subfolder by the name of /dwellir
// Otherwise, we assume that we run in the root folder
function getEnvironmentSpecificBasename(path: string) {
  if (path.startsWith("/dwellir")) {
    return "/dwellir/";
  }

  return "";
}
