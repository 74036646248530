import { useState, useEffect } from "react";
import { LinkButton, SubmitButton } from "../../components/buttons";
import classes from "./login.module.css";
import {
  Alert,
  Box,
  Center,
  Flex,
  NavLink,
  Paper,
  PasswordInput,
  Text,
  Title,
} from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { resetPassword, selectResetPassword } from "src/store/user";
import { PageRoute } from "src/utility/utils";
import { CopyrightIcon } from "src/ui/icons/copyright-icon";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";

interface ResetForm {
  password: string;
}

export const PasswordResetPage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const [error, setError] = useState<
    "MissingToken" | "BadToken" | "Unknown" | null
  >(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetRequest = useAppSelector(selectResetPassword);
  const token = searchParams.get("token");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (token == null) {
      setError("MissingToken");
    }
  }, [token]);

  useEffect(() => {
    if (resetRequest.state == "rejected") {
      if (resetRequest.error.type === "BadToken") {
        setError("BadToken");
      } else {
        setError("Unknown");
      }
    }

    if (resetRequest.state == "fulfilled") {
      setSuccess(true);
    }
  }, [resetRequest]);

  const form = useForm({
    initialValues: {
      password: "",
    },
  });

  const handleSubmit = (values: ResetForm) => {
    if (token) {
      dispatch(
        resetPassword({
          token,
          password: values.password,
        }),
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper h="100vw" className={classes.form} radius={0} px={30}>
        <Flex h="100%" direction="column" justify="space-between">
          <Box>
            <Flex direction="column" gap="lg">
              <Flex w="100%" justify="center">
                <DwellirLogo />
              </Flex>
              <Title order={2} className={classes.title} ta="center">
                Reset Your Password
              </Title>

              {error == null && !success && (
                <>
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <PasswordInput
                      mb="lg"
                      label={t("password_reset.input_label")}
                      placeholder={t("register.password_placeholder")}
                      {...form.getInputProps("password")}
                    />
                    <SubmitButton fullWidth mt="xl" size="md" text={"Reset"} />
                  </form>
                </>
              )}

              {error === "MissingToken" && (
                <Alert mt="md" radius="md" color="red">
                  Something is wrong, please try again later.
                </Alert>
              )}
              {error === "BadToken" && (
                <Alert mt="md" radius="md" color="red">
                  Your password reset request is invalid or expired.
                </Alert>
              )}

              {success && (
                <>
                  <Text fw={400}>
                    Your password has been reset successfully. You can now login
                    with your new password.
                  </Text>
                  <LinkButton
                    link={PageRoute.LOGIN}
                    text="Go to the login screen"
                  />
                </>
              )}
            </Flex>
          </Box>
          <Center>
            <NavLink
              w="auto"
              href="https://dwellir.com"
              label="Dwellir.com"
              variant="subtle"
              active
              color="green"
              leftSection={<CopyrightIcon />}
            />
          </Center>
        </Flex>
      </Paper>
    </div>
  );
};
