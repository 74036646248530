import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { SubmitButton } from "../../components/buttons";
import classes from "./login.module.css";
import { cookieSelector, isAuthenticated } from "src/store/authentication";
import {
  Paper,
  TextInput,
  PasswordInput,
  Title,
  Text,
  Alert,
  Flex,
  Box,
  Center,
  NavLink,
} from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useCookies } from "react-cookie";
import { PageRoute } from "src/utility/utils";
import { theme } from "src/ui/theme";
import { CopyrightIcon } from "src/ui/icons/copyright-icon";
import { login, selectLoginState } from "src/store/user";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";

interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingState = useAppSelector(selectLoginState);
  const [internalError, setInternalError] = useState<null | string>(null);
  const [cookies] = useCookies(cookieSelector);
  const [doRedirect, setDoRedirect] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (loadingState.state == "rejected") {
      if (loadingState.error.type === "LOGIN_BAD_CREDENTIALS") {
        // We can't set an error for the whole form it seems like, so we do it ourself
        setInternalError(t("login.bad_credentials"));
      }
    } else {
      setInternalError(null);
    }
  }, [loadingState, navigate, t]);

  const handleLogin = (values: LoginForm) => {
    if (values.email.length === 0) {
      form.setFieldError("email", "Please enter an Email");
    } else if (values.password.length === 0) {
      form.setFieldError("password", "Please enter a password");
    } else {
      dispatch(login({ username: values.email, password: values.password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated(cookies)) {
      setDoRedirect(true);
    }
  }, [cookies]);

  useEffect(() => {
    if (doRedirect) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("from");
      if (redirect) {
        setInternalError(null);
        navigate(redirect);
      } else {
        navigate(PageRoute.INDEX);
      }
    }
  }, [doRedirect, location, navigate]);

  return (
    <div className={classes.wrapper}>
      <Paper h="100vw" className={classes.form} radius={0} p={30}>
        <Flex h="100%" direction="column" justify="space-between">
          <Box>
            <Flex w="100%" justify="center">
              <DwellirLogo />
            </Flex>
            <Title
              order={2}
              className={classes.title}
              ta="center"
              mt="md"
              mb={50}
            >
              Welcome!
            </Title>

            <form onSubmit={form.onSubmit(handleLogin)}>
              <TextInput
                label="Email address"
                placeholder="hello@gmail.com"
                size="md"
                {...form.getInputProps("email")}
              />
              <PasswordInput
                styles={{
                  label: {
                    // Needed to be able to get the reset link on the right side.
                    width: "100%",
                  },
                }}
                label={
                  <Flex direction="row" justify="space-between">
                    <Text>Password</Text>
                    <Flex align="flex-end">
                      <Link
                        to={
                          PageRoute.FORGOT_PASSWORD +
                          `?email=${form.values.email}`
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <Text c={theme.colors.green[9]}>
                          Forgot your password?
                        </Text>
                      </Link>
                    </Flex>
                  </Flex>
                }
                placeholder="Your password"
                mt="md"
                size="md"
                {...form.getInputProps("password")}
              />
              {internalError && (
                <Alert mt="md" radius="md" color="red">
                  {internalError}
                </Alert>
              )}
              <SubmitButton fullWidth mt="md" size="md" text="Login" />
            </form>

            <Text ta="center" mt="md">
              Don&apos;t have an account?{" "}
              <Link
                to={PageRoute.REGISTER}
                style={{
                  textDecorationColor: theme.colors.green[9],
                  color: theme.colors.green[9],
                }}
              >
                {t("login.register")}
              </Link>
            </Text>
          </Box>
          <Center>
            <NavLink
              w="auto"
              href="https://dwellir.com"
              label="Dwellir.com"
              variant="subtle"
              active
              color="green"
              leftSection={<CopyrightIcon />}
            />
          </Center>
        </Flex>
      </Paper>
    </div>
  );
};
