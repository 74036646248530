import authReducer from "../authentication";
import userReducer from "../user";
import subscriptionReducer from "../subscription";
import checkoutReducer from "../checkout";
import chainReducer from "../chain";
import apiKeysReducer from "../api_keys";
import organizationReducer from "../organization";
import notificationsReducer from "../notifications";
import maintenanceReducer from "../maintenance";
import {
  combineReducers,
  configureStore,
  Reducer,
  UnknownAction,
} from "@reduxjs/toolkit";

const stateReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  user: userReducer,
  checkout: checkoutReducer,
  subscription: subscriptionReducer,
  chain: chainReducer,
  apiKeys: apiKeysReducer,
  notifications: notificationsReducer,
  maintenance: maintenanceReducer,
});

export const rootReducer: Reducer = (
  state: Partial<typeof stateReducer>,
  action: UnknownAction,
) => {
  if (action.type === "auth/logout/fulfilled") {
    return stateReducer(undefined, action);
  }
  return stateReducer(state, action);
};

export function setupStore(preloadedState: Partial<typeof stateReducer> = {}) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== "production",
  });
}
