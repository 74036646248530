import { FunctionComponent, ReactNode } from "react";
import { Header } from "../header/header";
import { Navbar } from "../navbar";
import { GuidedTour } from "../../guided-tour";
import { WelcomeModal } from "../../welcome-modal";
import { NotificationBell } from "../../notification-bell";
import { InternalUsersOnly } from "../../internal-users-only";
import { MaintenanceModal } from "../../maintenance-modal";
import { AppShell, Box, Burger, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLocation, useParams } from "react-router-dom";
import { getHeaderBasedOnRoute } from "src/utility/utils";
import { useUser } from "src/store/user";
import { ColorSchemeToggle } from "src/ui/components/color-scheme-toggle";
import OutsetaChatScript from "src/utility/OutsetaChatScript";

// TODO: tie user to Outseta CRM via Outseta access token, https://go.outseta.com/support/kb/articles/4XQYdemP/integrating-outseta-s-chat-widget-with-your-website

export const RootPanel: FunctionComponent<{
  children: ReactNode;
  withNavbar?: boolean;
  footer?: ReactNode;
  disableButtons?: boolean;
}> = ({ withNavbar, children, footer, disableButtons = false }) => {
  const [navbarOpen, { toggle: toggleNavbarOpen }] = useDisclosure();
  const currentRoute = useLocation().pathname;
  const { id: endpointId } = useParams(); // Capture endpointId from URL
  const user = useUser();

  return (
    <AppShell
      padding={0}
      header={{ height: 60 }}
      layout={footer ? "default" : "alt"}
      navbar={{
        width: withNavbar ? 275 : 0,
        breakpoint: "sm",
        collapsed: { mobile: !navbarOpen },
      }}
      footer={{ height: footer ? 60 : 0 }}
    >
      <MaintenanceModal />
      {/* Pages that has the menu buttons disabled should not show the WelcomeModal */}
      {!disableButtons && user.state === "fulfilled" && (
        <WelcomeModal user={user.data} />
      )}
      <OutsetaChatScript />
      <AppShell.Header withBorder={withNavbar ? false : true}>
        <Flex align="center" justify="space-between" p="md">
          <Flex align="center">
            <Burger
              opened={navbarOpen}
              onClick={toggleNavbarOpen}
              hiddenFrom="sm"
              mt="lg"
              ml="lg"
            />
            <Header text={getHeaderBasedOnRoute(currentRoute, { endpointId })}>
              {/*currentRoute === PageRoute.DASHBOARD && (
						<Button
							text={t("root_panel.header.create_endpoints_button") + " +"}
							link={PageRoute.SELECT_CHAIN}
						></Button>
					)*/}
            </Header>
          </Flex>
          <Flex align="center" gap="md">
            <InternalUsersOnly>
              <NotificationBell />
            </InternalUsersOnly>
            <GuidedTour tour={currentRoute} />
            <Box mr="md">
              <ColorSchemeToggle />
            </Box>
          </Flex>
        </Flex>
      </AppShell.Header>

      {withNavbar && (
        <AppShell.Navbar>
          <Navbar
            disableButtons={disableButtons}
            onBurgerClick={toggleNavbarOpen}
          />
        </AppShell.Navbar>
      )}

      <AppShell.Main h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
        {children}
      </AppShell.Main>

      {footer != null && <AppShell.Footer>{footer}</AppShell.Footer>}
    </AppShell>
  );
};
