import { FC, useEffect, useRef, useState } from "react";
import { RootPanel } from "../components/panels/root-panel/root-panel";
import { ChevronRightIcon } from "../icons/chevron-right-icon";
import { DwellirLogoBackground } from "../components/dwellir-logo-background";
import { SearchIcon } from "../icons/search-icon";
import { theme } from "../theme";
import { UrlClickToCopy } from "../components/url-click-to-copy";
import { RequestChain } from "../components/requst-chain";
import { Loading } from "../components/loading";
import classes from "./endpoints-search.module.css";
import {
  Text,
  NavLink,
  Paper,
  Flex,
  Box,
  Progress,
  alpha,
  Center,
  Grid,
  SelectProps,
  Image,
  Badge,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { endpointUrl } from "src/utility/endpoint";
import { useChains } from "src/store/chain";
import { Chain } from "src/api/chain";
import {
  useAnalyticsV4,
  useCurrentSubscription,
  useRpsAnalytics,
} from "src/store/user";
import { useUserApiKeys } from "src/store/api_keys";

interface ViewLinkProps {
  href: string;
  label: string;
}

export const ViewLink: FC<ViewLinkProps> = ({ href, label }) => {
  const navigate = useNavigate();
  // Use a button component and onClick here to prevent a full page reload
  // which causes us to loose our redux state.
  return (
    <NavLink
      label={label}
      component="button"
      onClick={() => navigate(href)}
      active
      fw={700}
      variant="subtle"
      color="green"
      rightSection={<ChevronRightIcon />}
    />
  );
};

interface HedingProps {
  label: string;
}

const Heading: FC<HedingProps> = ({ label }) => {
  return (
    <Flex direction="row" gap="md" align="center">
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
      <Text style={{ whiteSpace: "nowrap" }}>{label}</Text>
      <hr
        style={{
          width: "100%",
          height: "0px",
          border: "1px solid #94CFBE",
        }}
      />
    </Flex>
  );
};

const AccountStatsCard = () => {
  const { t } = useTranslation();
  const analytics = useAnalyticsV4("day", "startOfMonth");
  const rpsAnalytics = useRpsAnalytics();
  const subscription = useCurrentSubscription();

  const [responsesThisMonth, setResponsesThisMonth] = useState<
    number | undefined
  >(undefined);
  const [progressThisMonth, setProgressThisMonth] = useState<number>(0);
  const [prognosisThisMonth, setPrognosisThisMonth] = useState<number>(0);

  useEffect(() => {
    if (analytics.state === "fulfilled" && subscription.state === "fulfilled") {
      const responsesThisMonth = analytics.data.rows.reduce(
        (acc, row) => row.requests + acc,
        0,
      );
      setResponsesThisMonth(responsesThisMonth);

      const progressThisMonth =
        responsesThisMonth / subscription.data.monthlyQuota;
      setProgressThisMonth(progressThisMonth);

      // Simple linear prognosis, if we want to do something fancier we should
      // move the logic to the backend.
      const daysInMonth = (year: number, month: number) =>
        new Date(year, month, 0).getDate();
      const today = new Date();
      const daysThisMonth = daysInMonth(
        today.getFullYear(),
        today.getMonth() + 1,
      );
      const daysSoFar = today.getDate();
      const daysLeftThisMonth = daysThisMonth - daysSoFar;

      const averageResponsesPerDay = responsesThisMonth / daysSoFar;
      const estimated = averageResponsesPerDay * daysLeftThisMonth;
      setPrognosisThisMonth(
        progressThisMonth + estimated / subscription.data.monthlyQuota,
      );
    }
  }, [analytics, subscription]);

  return (
    <Paper
      p={{ base: "md", lg: "xl", xl: "xl" }}
      radius="md"
      shadow="md"
      bg="second-background"
      mb="md"
      w="100%"
    >
      <Flex direction="row" justify="space-between">
        <Flex direction="column">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading
            isLoading={
              analytics.state === "loading" || subscription.state == "loading"
            }
          >
            <Box pb="4px">
              <Center>
                <Text
                  c="green"
                  ta="right"
                  size="calc(2.5rem * var(--mantine-scale))"
                  fw={700}
                >
                  {responsesThisMonth != null
                    ? t("format.number", {
                        number: responsesThisMonth,
                      })
                    : "-"}
                </Text>
              </Center>
            </Box>
            <Flex direction="row" w="100%" justify="flex-end">
              <Text size="md" fw={600}>
                /{" "}
                {subscription.state === "fulfilled"
                  ? t("format.number", {
                      number: subscription.data.monthlyQuota,
                    })
                  : "-"}{" "}
                RESPONSES
              </Text>
            </Flex>
            <Progress.Root size="lg" radius="md">
              <Progress.Section value={progressThisMonth} color="green" />
              <Progress.Section
                value={prognosisThisMonth}
                color={alpha(theme.colors.green[6], 0.15)}
                style={{
                  border: "1px dashed var(--mantine-color-green-6)",
                  borderLeft: "unset",
                }}
              />
            </Progress.Root>
          </Loading>
        </Flex>

        <Flex direction="column">
          <Box pb="8px">
            <Heading label="This Month" />
          </Box>
          <Loading isLoading={rpsAnalytics.state === "loading"}>
            <Grid columns={8} gutter="0px">
              <Grid.Col span={3}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.rps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      AVERAGE RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={2}>
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.yellow[3]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.peakRps,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      PEAK RPS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>

              <Grid.Col span={3} w="22em">
                <Flex direction="column">
                  <Box pb="4px">
                    <Center>
                      <Text
                        c={theme.colors.red[7]}
                        size="calc(2.5rem * var(--mantine-scale))"
                        fw={700}
                      >
                        {rpsAnalytics.state === "fulfilled"
                          ? t("format.number", {
                              number: rpsAnalytics.data.limitedRequests,
                            })
                          : "-"}
                      </Text>
                    </Center>
                  </Box>
                  <Center>
                    <Text size="lg" fw={600}>
                      LIMITED REQUESTS
                    </Text>
                  </Center>
                </Flex>
              </Grid.Col>
            </Grid>
          </Loading>
        </Flex>

        <Flex direction="column">
          <Box pb="8px">
            <Heading label="Your Plan's RPS Limits" />
          </Box>
          <Loading isLoading={subscription.state === "loading"}>
            <Flex direction="row" gap="md">
              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.rateLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  RATE LIMIT
                </Text>
              </Flex>

              <Flex direction="column">
                <Box pb="4px">
                  <Center>
                    <Text size="calc(2.5rem * var(--mantine-scale))" fw={700}>
                      {subscription.state === "fulfilled"
                        ? t("format.number", {
                            number: subscription.data.burstLimit,
                          })
                        : "-"}
                    </Text>
                  </Center>
                </Box>
                <Text size="lg" fw={600}>
                  BURST LIMIT
                </Text>
              </Flex>
            </Flex>
          </Loading>
        </Flex>
      </Flex>
    </Paper>
  );
};

const EndpointsSearch = () => {
  interface Data {
    label: string;
    imageUrl: string;
    networkName: string;
    nodeType: string;
    https?: string;
    wss?: string;
  }
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);
  const [data, setData] = useState<Data[]>([]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [showResults, setShowResults] = useState(false);
  const [filter, setFilter] = useState("");
  const chains = useChains();
  const apiKeys = useUserApiKeys();
  const [filteredChains, setFilteredChains] = useState<Chain[] | null>(null);

  useEffect(() => {
    function handleResize() {
      if (scrollRef.current?.clientHeight) {
        setMaxScrollHeight(scrollRef.current?.clientHeight);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (chains.state === "fulfilled") {
      setFilteredChains(chains.data);
    }
  }, [chains]);

  useEffect(() => {
    if (filter === "" || chains.state !== "fulfilled") {
      setShowResults(false);
    } else {
      const filteredChains = chains.data.filter(
        (chain) =>
          chain.name.toLowerCase().includes(filter.toLowerCase()) ||
          chain.networks.some((network) =>
            network.name.toLowerCase().includes(filter.toLowerCase()),
          ),
      );
      // Sort based on if the Chain starts with filter or not
      filteredChains.sort((a: Chain, b: Chain) => {
        const aStarts = a.name.toLowerCase().startsWith(filter.toLowerCase());
        const bStarts = b.name.toLowerCase().startsWith(filter.toLowerCase());
        if (aStarts && !bStarts) {
          return -1;
        }
        if (!aStarts && bStarts) {
          return 1;
        }
        return 0;
      });
      setFilteredChains(filteredChains);
      setShowResults(true);
    }
  }, [chains, filter]);

  useEffect(() => {
    let key = "<API_KEY>";
    if (apiKeys && apiKeys.length >= 1) {
      key = apiKeys[0].apiKey;
    }
    if (filteredChains) {
      const data = filteredChains.flatMap((chain) => {
        return chain.networks.flatMap((network) => {
          return {
            label: chain.name,
            imageUrl: chain.imageUrl,
            networkName: network.name,
            nodeType: network.nodeType,
            https: network.https ? endpointUrl(network.https, key) : undefined,
            wss: network.wss ? endpointUrl(network.wss, key) : undefined,
          };
        });
      });
      setData(data);
    }
  }, [filteredChains, apiKeys]);

  const renderSelectOption: SelectProps["renderOption"] = ({ option }) => {
    const chain = data[Number(option.value)];
    return (
      <Paper
        w="100%"
        py="xs"
        px="sm"
        bg={alpha(theme.colors.green[6], 0.15)}
        shadow="none"
        radius="md"
      >
        <Flex
          direction="row"
          w="inherit"
          justify="space-between"
          align="center"
        >
          <Flex direction="row" gap="xs" align="inherit">
            <Image h={48} w={48} src={chain.imageUrl} />
            <Text size="xl" fw={400}>
              {option.label}
            </Text>
            <Badge
              variant="outline"
              color="primaryText"
              classNames={{
                root:
                  chain.networkName.toLowerCase() === "mainnet"
                    ? classes.badge_mainnet
                    : classes.badge_testnet,
              }}
            >
              {chain.networkName}
            </Badge>
            <Badge
              variant="outline"
              color="primaryText"
              classNames={{
                root:
                  chain.nodeType.toLowerCase() === "archive"
                    ? classes.badge_archive
                    : classes.badge_full,
              }}
            >
              {chain.nodeType}
            </Badge>
          </Flex>
          <Flex gap="sm">
            {chain.https && (
              <UrlClickToCopy
                variant="filled"
                color={theme.colors.green[6]}
                url={chain.https}
                text="HTTPS"
                width={"4em"}
              />
            )}
            {chain.wss && (
              <UrlClickToCopy
                variant="filled"
                color={theme.colors.green[6]}
                url={chain.wss}
                text="Websocket"
                width={"5em"}
              />
            )}
          </Flex>
        </Flex>
      </Paper>
    );
  };

  return (
    <Flex direction="column" mx="xl" mt="xl" mb="5vh" h="100%" ref={scrollRef}>
      <Paper mx="xl" shadow="xl">
        <TextInput
          classNames={{
            input: showResults ? classes.input : undefined,
          }}
          size="xl"
          placeholder="Search Endpoints"
          value={filter}
          onChange={(event) => setFilter(event.currentTarget.value)}
          leftSection={<SearchIcon />}
        />
        {showResults && (
          <Paper
            classNames={{
              root: classes.dropdown,
            }}
            bg="background"
            withBorder
          >
            {data.length === 0 ? (
              <Box p="xs">
                <RequestChain initialRequest={filter} />
              </Box>
            ) : (
              <ScrollArea.Autosize mah={maxScrollHeight}>
                <Flex direction="column" m="xs" gap="md">
                  {data.map(
                    (chain, index) =>
                      renderSelectOption &&
                      renderSelectOption({
                        option: { value: index.toString(), label: chain.label },
                        checked: false,
                      }),
                  )}
                </Flex>
              </ScrollArea.Autosize>
            )}
          </Paper>
        )}
      </Paper>
    </Flex>
  );
};

export const Dashboard = () => {
  return (
    <RootPanel withNavbar>
      <Box h="100%" p="xl">
        <DwellirLogoBackground>
          <Flex h="100%" direction="column" p="md">
            <EndpointsSearch />
            <Flex flex="1" align="flex-end">
              <AccountStatsCard />
            </Flex>
          </Flex>
        </DwellirLogoBackground>
      </Box>
    </RootPanel>
  );
};
