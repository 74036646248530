import { FC, useEffect, useState } from "react";
import stripeTaxUtils from "stripe-tax-utils";

import { countryName, currentCountry } from "src/utility/countries";
import { Group, Image, Select } from "@mantine/core";

interface CountrySelectorProps {
  onChange: (countryCode: string) => void;
}

export const CountrySelector: FC<CountrySelectorProps> = ({ onChange }) => {
  const countries = [
    ...new Set(stripeTaxUtils.getMap().map((entry) => entry.country)),
  ].map((code) => ({
    value: code,
    label: countryName(code) || code,
  }));
  const guessed = currentCountry();
  const [countryCode, setCountryCode] = useState<string>(
    guessed?.id || countries[0].value,
  );

  useEffect(() => {
    onChange(countryCode || guessed?.id || countries[0].value);
  }, [onChange, countryCode, guessed, countries]);

  const renderSelectOption = ({
    option,
  }: {
    option: { value: string; label: string };
  }) => {
    return (
      <Group flex="1" gap="xs">
        <Image
          h={15}
          w={23}
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.value}.svg`}
        />
        {countryName(option.label) || option.label}
      </Group>
    );
  };

  const realOnChange = (value: string | null) => {
    if (value) {
      setCountryCode(value);
    }
  };

  return (
    <Select
      label="Tax Country"
      withAsterisk
      description="The country for which we need to collect taxes in"
      withCheckIcon={false}
      searchable
      renderOption={renderSelectOption}
      leftSection={
        <Image
          h={15}
          w={23}
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
        />
      }
      onChange={realOnChange}
      data={countries}
      value={countryCode || countries[0].value}
    />
  );
};
