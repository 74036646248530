import React, { useEffect } from "react";

const OutsetaScript: React.FC = () => {
  useEffect(() => {
    // Define the Outseta options
    const scriptOptions = document.createElement("script");
    scriptOptions.type = "text/javascript";
    scriptOptions.innerHTML = `
      var o_options = {
        domain: 'dwellir.outseta.com',
        load: 'chat'
      };
    `;
    document.head.appendChild(scriptOptions);

    // Load the Outseta script with the defined options
    const scriptOutseta = document.createElement("script");
    scriptOutseta.src = "https://cdn.outseta.com/outseta.min.js";
    scriptOutseta.async = true;
    scriptOutseta.setAttribute("data-options", "o_options");
    document.head.appendChild(scriptOutseta);

    // Cleanup scripts on unmount
    return () => {
      document.head.removeChild(scriptOptions);
      document.head.removeChild(scriptOutseta);
    };
  }, []);

  return null;
};

export default OutsetaScript;
