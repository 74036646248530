import { FC } from "react";
import { Button, DangerButton } from "../buttons";
import { Anchor, Flex, List, Modal, Paper, Text, Title } from "@mantine/core";
import { User } from "src/api/marly";

interface ToSModalProps {
  show: boolean;
  user?: User;
  onCloseDisagree: () => void;
  onCloseAccept: () => void;
}

interface ContentProps {
  currentTos: string;
}

export const getTosVersion = (user: User | undefined): string => {
  let version = "April 03, 2024";
  if (user && user.email.toLowerCase() === "karim@parity.io") {
    version = "August 14, 2024";
  }
  return version;
};

const DefaultTos: FC<ContentProps> = ({ currentTos }) => (
  <>
    <Title>Terms of Service Agreement</Title>
    <Text>Last Updated:</Text>
    <Text mb="md">{currentTos}</Text>

    <List type="ordered" spacing="sm" mb="md">
      <List.Item>
        <Title order={5}>Introduction</Title>
        <Text>
          Welcome to Dwellir AB ("Dwellir," "we," "our," or "us"). These Terms
          of Service ("Terms") govern your access to and use of our API services
          that provide access to various blockchain networks (the "Services").
          By using our Services, you agree to these Terms. If you do not agree
          to these Terms, please do not use our Services.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Eligibility</Title>
        <Text>
          To use our Services, you must be at least 18 years old and have the
          legal capacity to enter into binding agreements. By using our
          Services, you represent that you meet these requirements.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Account Registration</Title>
        <Text>
          To access certain features of our Services, you may be required to
          create an account. You agree to provide accurate and complete
          information during the registration process and to update it as
          necessary. You are responsible for maintaining the confidentiality of
          your account information and are liable for all activities that occur
          under your account.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Use of Services</Title>
        <Text>
          You agree to use our Services only for lawful purposes and in
          accordance with these Terms. You shall not use our Services to:
        </Text>
        <Text>
          - Engage in illegal activities or promote illegal activities.
        </Text>
        <Text>
          - Violate the rights of others, including intellectual property
          rights, privacy, and data protection rights.
        </Text>
        <Text> - Interfere with or disrupt our Services or servers.</Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Pricing and Payment</Title>
        <Text>
          Access to our Services may require payment of fees. Our pricing
          information is available on our website and may change from time to
          time. Payment is due at the time specified in your agreement with us.
          Failure to make timely payments may result in suspension or
          termination of your access to the Services.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Data Protection and Privacy</Title>
        <Text>
          We comply with the General Data Protection Regulation (GDPR) and other
          applicable EU laws regarding data protection and privacy. Your
          personal information is handled according to our Privacy Policy, which
          can be found{" "}
          <Anchor
            c="green"
            href="https://www.dwellir.com/privacy-policy"
            target="_blank"
          >
            link to Privacy Policy
          </Anchor>
          . By using our Services, you consent to our collection, use, and
          sharing of your information as described in the Privacy Policy.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Intellectual Property</Title>
        <Text>
          The Services and all content, trademarks, and other intellectual
          property rights are owned by Dwellir or its licensors. You agree not
          to copy, modify, or distribute any content from our Services without
          our prior written consent.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Termination </Title>
        <Text>
          We reserve the right to terminate or suspend your access to our
          Services at our discretion, without prior notice, for violation of
          these Terms or any applicable law. You may also terminate your account
          at any time by notifying us in writing.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Limitation of Liability</Title>
        <Text>
          To the fullest extent permitted by law, Dwellir shall not be liable
          for any direct, indirect, incidental, special, or consequential
          damages arising out of or in connection with your use or inability to
          use our Services, even if we have been advised of the possibility of
          such damages.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Indemnification</Title>
        <Text>
          You agree to indemnify and hold Dwellir harmless from any claims,
          damages, losses, or expenses (including attorney's fees) arising from
          your use of the Services, your violation of these Terms, or your
          infringement of any rights of others.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Governing Law and Jurisdiction</Title>
        <Text>
          These Terms shall be governed by and construed in accordance with the
          laws of Sweden and the European Union. Any dispute, controversy or
          claim arising out of or in connection with this contract, or the
          breach, termination or invalidity thereof, shall be finally settled by
          arbitration in accordance with the Rules for Expedited Arbitrations of
          the Arbitration Institute of the Stockholm Chamber of Commerce.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Changes to Terms</Title>
        <Text>
          We may update these Terms from time to time. We will notify you of any
          material changes by posting the new Terms on our website. Your
          continued use of our Services after such changes constitutes your
          acceptance of the updated Terms.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Contact Information </Title>
        <Text mb="md">
          If you have any questions about these Terms, please contact us at
          info@dwellir.com
        </Text>

        <Text>Drottninggatan 2</Text>
        <Text>75310 Uppsala, Sweden</Text>
        <Anchor c="green" href="https://www.dwellir.com" target="_blank">
          www.dwellir.com
        </Anchor>
      </List.Item>
    </List>
  </>
);

const ParityTos: FC<ContentProps> = ({ currentTos }) => (
  <>
    <Title>Terms of Service Agreement</Title>
    <Text>Last Updated:</Text>
    <Text mb="md">{currentTos}</Text>

    <List type="ordered" spacing="sm" mb="md">
      <List.Item>
        <Title order={5}>Introduction</Title>
        <Text>
          Welcome to Dwellir AB ("Dwellir," "we," "our," or "us"). These Terms
          of Service ("Terms") govern your access to and use of our API services
          that provide access to various blockchain networks (the "Services").
          By using our Services, you agree to these Terms. If you do not agree
          to these Terms, please do not use our Services.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Eligibility</Title>
        <Text>
          To use our Services, you must be at least 18 years old and have the
          legal capacity to enter into binding agreements. By using our
          Services, you represent that you meet these requirements.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Account Registration</Title>
        <Text>
          To access certain features of our Services, you may be required to
          create an account. You agree to provide accurate and complete
          information during the registration process and to update it as
          necessary. You are responsible for maintaining the confidentiality of
          your account information and are liable for all activities that occur
          under your account.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Use of Services</Title>
        <Text>
          You agree to use our Services only for lawful purposes and in
          accordance with these Terms. You shall not use our Services to:
        </Text>
        <Text>
          - Engage in illegal activities or promote illegal activities.
        </Text>
        <Text>
          - Violate the rights of others, including intellectual property
          rights, privacy, and data protection rights.
        </Text>
        <Text> - Interfere with or disrupt our Services or servers.</Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Pricing and Payment</Title>
        <Text>
          Access to our Services may require payment of fees. Our pricing
          information is available on our website and may change from time to
          time. Payment is due at the time specified in your agreement with us.
          Failure to make timely payments may result in suspension or
          termination of your access to the Services.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Data Protection and Privacy</Title>
        <Text>
          We comply with the General Data Protection Regulation (GDPR) and other
          applicable EU laws regarding data protection and privacy. Your
          personal information is handled according to our Privacy Policy, which
          can be found{" "}
          <Anchor
            c="green"
            href="https://www.dwellir.com/privacy-policy"
            target="_blank"
          >
            link to Privacy Policy
          </Anchor>
          . By using our Services, you consent to our collection, use, and
          sharing of your information as described in the Privacy Policy.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Intellectual Property</Title>
        <Text>
          The Services and all content, trademarks, and other intellectual
          property rights are owned by Dwellir or its licensors. You agree not
          to copy, modify, or distribute any content from our Services without
          our prior written consent.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Termination </Title>
        <Text>
          We reserve the right to terminate or suspend your access to our
          Services at our discretion, without prior notice, for violation of
          these Terms or any applicable law. You may also terminate your account
          at any time by notifying us in writing.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Limitation of Liability</Title>
        <Text mb="sm">
          To the fullest extent permitted by law, Dwellir shall not be liable
          for any direct, indirect, incidental, special, or consequential
          damages arising out of or in connection with your use or inability to
          use our Services, even if we have been advised of the possibility of
          such damages.
        </Text>
        <Text>
          To the fullest extent permitted by law, you shall not be liable for
          any indirect, incidental, special, or consequential damages to us if
          you have been advised of the possibility of such damages. Your
          aggregate liability under these Terms, whether based in contact, tort
          (including negligence), strict liability or otherwise will not exceed
          the fees paid by you during the 12 months immediately preceding the
          claim.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Indemnification</Title>
        <Text>
          You agree to indemnify and hold Dwellir harmless from any claims,
          damages, losses, or expenses (including attorney's fees) arising from
          your use of the Services, your violation of these Terms, or your
          infringement of any rights of others.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Governing Law and Jurisdiction</Title>
        <Text>
          These Terms shall be governed by and construed in accordance with the
          laws of Sweden and the European Union. Any dispute, controversy or
          claim arising out of or in connection with this contract, or the
          breach, termination or invalidity thereof, shall be finally settled by
          arbitration in accordance with the Rules for Expedited Arbitrations of
          the Arbitration Institute of the Stockholm Chamber of Commerce.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Changes to Terms</Title>
        <Text>
          We may update these Terms from time to time. We will notify you of any
          material changes by posting the new Terms on our website. Your
          continued use of our Services after such changes constitutes your
          acceptance of the updated Terms.
        </Text>
      </List.Item>
      <List.Item>
        <Title order={5}>Contact Information </Title>
        <Text mb="md">
          If you have any questions about these Terms, please contact us at
          info@dwellir.com
        </Text>

        <Text>Drottninggatan 2</Text>
        <Text>75310 Uppsala, Sweden</Text>
        <Anchor c="green" href="https://www.dwellir.com" target="_blank">
          www.dwellir.com
        </Anchor>
      </List.Item>
    </List>
  </>
);

export const ToSModal: FC<ToSModalProps> = ({
  show,
  user,
  onCloseDisagree,
  onCloseAccept,
}) => {
  const currentTos = getTosVersion(user);
  let content = <DefaultTos currentTos={currentTos} />;
  if (user && user.email.toLowerCase() === "karim@parity.io") {
    content = <ParityTos currentTos={currentTos} />;
  }
  return (
    <Modal size="auto" opened={show} onClose={onCloseDisagree} title="">
      <Paper h="100vw" w="40vw" radius={0} p={30}>
        {content}

        <Flex direction="row" justify="flex-end" gap="md" pb="md">
          <DangerButton
            text="I disagree with these terms"
            onClick={onCloseDisagree}
          />
          <Button text="I agree with these terms" onClick={onCloseAccept} />
        </Flex>
      </Paper>
    </Modal>
  );
};
