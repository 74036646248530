import { FC } from "react";
import { BaseIconProps, Icon } from "./base/icon";

export const CircleIcon: FC<BaseIconProps> = (props) => {
  return (
    <Icon {...props}>
      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
    </Icon>
  );
};
