import React, { FC } from "react";
import { GuidedTour } from "../../guided-tour";
import classes from "./navbar.module.css";
import { Button, Burger, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link, generatePath, useNavigate, useLocation } from "react-router-dom";
import { PageRoute, UsageBillingTabValue } from "src/utility/utils";
import { EndpointIcon } from "src/ui/icons/endpoint-icon";
import { HomeIcon } from "src/ui/icons/home-icon";
import { ChartIcon } from "src/ui/icons/chart-icon";
import { WalletIcon } from "src/ui/icons/wallet-icon";
import { KeyIcon } from "src/ui/icons/key-icon";
import { SupportIcon } from "src/ui/icons/support-icon";
import { DedicatedNodesIcon } from "src/ui/icons/dedicated-nodes-icon";
import { GearIcon } from "src/ui/icons/gear-icon";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";
import { PhoneIcon } from "src/ui/icons/phone-icon";

type NavbarProps = {
  onBurgerClick?: () => void;
  disableButtons?: boolean;
};

export const Navbar = ({
  onBurgerClick,
  disableButtons = false,
}: NavbarProps) => {
  const { t } = useTranslation();
  return (
    <Flex h="100%" direction="column" bg="second-background">
      <Flex className={classes.header} justify="space-between" align="center">
        <Burger opened={true} onClick={onBurgerClick} hiddenFrom="sm" />
        <Link to={PageRoute.DASHBOARD}>
          <DwellirLogo />
        </Link>
      </Flex>
      <Flex h="100%" p="md" direction="column" justify="space-between">
        <Flex direction="column">
          <NavbarButton
            id="dashboard-button"
            to={PageRoute.DASHBOARD}
            disabled={disableButtons}
            text={t("root_panel.navbar.dashboard_button")}
            icon={<HomeIcon />}
          />
          <NavbarButton
            id="endpoints-button"
            to={PageRoute.ENDPOINTS}
            disabled={disableButtons}
            text={t("root_panel.navbar.endpoints_button")}
            icon={<EndpointIcon />}
          />
          <NavbarButton
            id="dedicated-nodes-button"
            to={PageRoute.DEDICATED_NODES}
            disabled={disableButtons}
            text={t("root_panel.navbar.dedicated_nodes_button")}
            icon={<DedicatedNodesIcon />}
          />
          <NavbarButton
            id="usage-button"
            to={generatePath(PageRoute.USAGE_BILLING_TABS, {
              tabValue: UsageBillingTabValue.USAGE,
            })}
            disabled={disableButtons}
            text={t("root_panel.navbar.usage_button")}
            icon={<ChartIcon />}
          />
        </Flex>
        <Flex direction="column">
          <SupportButton
            disabled={disableButtons}
            text={t("root_panel.navbar.support_button")}
            icon={<SupportIcon />}
          />
          <ExternalLinkButton
            id="call-us-button"
            to={"https://cal.com/dwellir/feedback"}
            disabled={disableButtons}
            text={t("root_panel.navbar.call_us_button")}
            icon={<PhoneIcon />}
          />
          <NavbarButton
            id="api-keys-button"
            to={PageRoute.API_KEYS}
            disabled={disableButtons}
            text={t("root_panel.navbar.api_keys_buton")}
            icon={<KeyIcon />}
          />
          <NavbarButton
            id="billing-button"
            to={generatePath(PageRoute.USAGE_BILLING_TABS, {
              tabValue: UsageBillingTabValue.BILLING,
            })}
            disabled={disableButtons}
            text={t("root_panel.navbar.billing_button")}
            icon={<WalletIcon />}
          />
          <NavbarButton
            id="profile-button"
            to={PageRoute.SETTINGS}
            disabled={disableButtons}
            text={t("root_panel.navbar.settings_button")}
            icon={<GearIcon />}
          />
          <GuidedTour tour="navbar" />
        </Flex>
      </Flex>
    </Flex>
  );
};

type NavbarButtonProps = {
  id: string;
  text: string;
  to: string;
  disabled: boolean;
  icon: React.ReactElement;
};

type SupportButtonProps = {
  text: string;
  disabled: boolean;
  icon: React.ReactElement;
};

const NavbarButton = ({ id, text, to, disabled, icon }: NavbarButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === to;

  const buttonClass = `${classes.button} ${
    isActive ? classes.active : classes["non-active"]
  }`;

  return (
    <Button
      id={id}
      className={buttonClass}
      variant="light"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
      onClick={() => navigate(to)}
    >
      <span style={{ marginRight: 10 }}>{icon}</span> {text}
    </Button>
  );
};

const SupportButton = ({ text, disabled, icon }: SupportButtonProps) => {
  const buttonClass = `${classes.button} ${classes["non-active"]}`;

  return (
    <Button
      id="support-button"
      className={buttonClass}
      variant="default"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
    >
      {icon && <span style={{ marginRight: 10 }}>{icon}</span>} {text}
    </Button>
  );
};

type ExternalLinkButtonProps = {
  id: string;
  text: string;
  to: string;
  disabled: boolean;
  icon: React.ReactElement;
};

const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({
  id,
  to,
  text,
  disabled,
  icon,
}) => {
  const buttonClass = `${classes.button} ${classes["non-active"]}`;

  return (
    <Button
      id={id}
      component="a"
      href={to}
      target="_blank"
      className={buttonClass}
      variant="default"
      size="xs"
      disabled={disabled}
      justify="left"
      mb="md"
    >
      {icon && <span style={{ marginRight: 10 }}>{icon}</span>} {text}
    </Button>
  );
};
