import { useState } from "react";
import { RootPanel } from "../components";
import { Loading } from "../components/loading";
import { SubmitButton } from "../components/buttons";
import { requestDedicatedNode, useUser } from "src/store/user";
import { Alert, Flex, Paper, Text, TextInput, Textarea } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useAppDispatch } from "src/core/hooks";
import { DedicatedNodeRequest } from "src/api/user";

export const DedicatedNodes = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useUser();
  const [submitted, setSubmitted] = useState(false);
  const form = useForm({
    initialValues: {
      chain: "",
      otherInformation: "",
    },
  });

  const handleSubmit = (values: DedicatedNodeRequest) => {
    setSubmitted(true);
    dispatch(requestDedicatedNode(values));
  };

  return (
    <RootPanel withNavbar>
      <Paper
        shadow="lg"
        p="xl"
        radius="md"
        mx={{ base: "sm", md: "sm", lg: "25vw", xl: "25vw" }}
        mt="lg"
        withBorder
      >
        <Flex direction="column" gap="md">
          <Text>Request a dedicated node</Text>

          <Text>
            Use this form to request a dedicated node for a specific chain. We
            are able to run dedicated nodes for most chains even if you are not
            the developer of the chain itself.
          </Text>

          <Loading isLoading={user.state === "loading"}>
            <Flex direction="column" gap="md">
              {user.state === "fulfilled" && (
                <TextInput
                  label={t("dedicated_node_form.email")}
                  placeholder="user@example.com"
                  disabled
                  readOnly
                  value={user.data.email}
                />
              )}

              <form onSubmit={form.onSubmit(handleSubmit)}>
                <Flex direction="column" gap="md">
                  <TextInput
                    label={t("dedicated_node_form.chain")}
                    description={t("dedicated_node_form.chain_description")}
                    placeholder={t("dedicated_node_form.chain_placeholder")}
                    {...form.getInputProps("chain")}
                  />

                  <Textarea
                    label={t("dedicated_node_form.other_information")}
                    description={t(
                      "dedicated_node_form.other_information_description",
                    )}
                    placeholder=""
                    {...form.getInputProps("otherInformation")}
                  />
                  <SubmitButton
                    disabled={
                      !(
                        form.isDirty("chain") &&
                        form.isDirty("otherInformation")
                      ) || submitted
                    }
                    text="Send request"
                  />
                </Flex>
              </form>

              {submitted && (
                <Alert
                  variant="light"
                  color="blue"
                  title="Thank you for your interest in our dedicated nodes!"
                >
                  <Text>
                    We will reach out to you with a quote and further
                    information.
                  </Text>
                </Alert>
              )}
            </Flex>
          </Loading>
        </Flex>
      </Paper>
    </RootPanel>
  );
};
