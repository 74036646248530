import { FC, useState } from "react";
import { SubmitButton } from "src/ui/components/buttons";
import { Loading } from "src/ui/components/loading";
import { TaxId } from "src/ui/components/tax-id";
import { CountrySelector } from "src/ui/components/country-selector";
import { Paper, TextInput, Text, Checkbox, Flex, Alert } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useUser } from "src/store/user";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { useForm } from "@mantine/form";
import { OrganizationInformation } from "src/api/organization";
import {
  selectInformationUpdateState,
  updateOrganizationInformation,
} from "src/store/organization";

export interface SettingsCardProps {
  information: OrganizationInformation;
  requiredOnly?: boolean;
  saveLabel?: string;
  onSaved?: () => void;
}

export const SettingsCard: FC<SettingsCardProps> = ({
  information,
  requiredOnly = false,
  saveLabel = "Save",
  onSaved = () => {
    return;
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useUser();
  const [countryCode, setCountryCode] = useState<string>("");
  const [taxId, setTaxId] = useState<string | undefined>(information.taxId);
  const [hasError, setHasError] = useState<boolean>(false);
  const [stripeTaxIdType, setStripeTaxIdType] = useState<string | undefined>(
    undefined,
  );
  const updateState = useAppSelector(selectInformationUpdateState);

  interface formValues {
    name: string;
    billingAddress1: string;
    billingAddress2: string;
    billingPostalCode: string;
    billingCity: string;
    billingPhone: string;
    isBusiness: boolean;
  }

  const form = useForm<formValues>({
    initialValues: {
      name: information.name || "",
      billingAddress1: information.billingAddress1 || "",
      billingAddress2: information.billingAddress2 || "",
      billingPostalCode: information.billingPostalCode || "",
      billingCity: information.billingCity || "",
      billingPhone: information.billingPhone || "",
      isBusiness: information.isBusiness || false,
    },
  });

  const handleSave = (values: formValues) => {
    // Check if the email input field has an extra valid email address
    const update: OrganizationInformation = {
      billingCountry: countryCode,
      stripeTaxIdType,
      taxId,
      // We can't change canChangeTaxId it is just here to satisfy the type
      canChangeTaxId: information.canChangeTaxId,
      ...values,
    };

    dispatch(updateOrganizationInformation(update)).then(() => {
      onSaved();
    });
  };

  const showFullForm = !requiredOnly;

  return (
    <Paper
      shadow="lg"
      p="xl"
      radius="md"
      mx={{ base: "sm", md: "sm", lg: "25vw", xl: "25vw" }}
      mt="lg"
      withBorder
    >
      <Loading isLoading={user.state === "loading"}>
        <form
          onSubmit={form.onSubmit((values) => {
            handleSave(values);
          })}
        >
          <Flex direction="column" gap="md">
            {showFullForm && (
              <TextInput
                label={"Name"}
                placeholder={"Organiztion name"}
                {...form.getInputProps("name")}
              />
            )}
            {user.state === "fulfilled" && showFullForm && (
              <TextInput
                label={t("profile.email")}
                description={t("profile.email_description")}
                placeholder="user@example.com"
                disabled
                readOnly
                value={user.data.email}
              />
            )}
            <Flex direction="column">
              <Text fw={700} mt="md">
                Billing Information
              </Text>
            </Flex>
            {showFullForm && (
              <Checkbox
                label="Do you represent a business?"
                {...form.getInputProps("isBusiness", { type: "checkbox" })}
              />
            )}
            <CountrySelector onChange={setCountryCode} />
            {showFullForm && (
              <Flex direction="column" gap="xs">
                <Text>Address</Text>
                <TextInput
                  placeholder={"Adress line 1"}
                  {...form.getInputProps("billingAddress1")}
                />
                <TextInput
                  placeholder={"Adress line 2"}
                  {...form.getInputProps("billingAddress2")}
                />
                <TextInput
                  placeholder={"City"}
                  {...form.getInputProps("billingCity")}
                />
                <TextInput
                  placeholder={"Postal Code"}
                  {...form.getInputProps("billingPostalCode")}
                />
              </Flex>
            )}
            {showFullForm && (
              <TextInput
                label={"Phone"}
                placeholder={"Phone"}
                {...form.getInputProps("billingPhone")}
              />
            )}
            <TaxId
              value={taxId}
              countryCode={countryCode}
              disabled={!information.canChangeTaxId}
              hasError={setHasError}
              onChange={(taxId, stripeTaxIdType) => {
                setTaxId(taxId);
                setStripeTaxIdType(stripeTaxIdType);
              }}
            />
            {showFullForm && (
              <Alert variant="light" color="blue">
                If you want to get invoices and receipts sent to a different
                email address please reach out to support for assistance.
              </Alert>
            )}
            <SubmitButton
              text={saveLabel}
              disabled={hasError || !form.isValid()}
              loading={updateState.state === "loading"}
            />
          </Flex>
        </form>
      </Loading>
    </Paper>
  );
};
