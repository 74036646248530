import { ReactNode, useEffect, useState } from "react";
import { SubmitButton } from "../../components/buttons";
import classes from "./register.module.css";
import { register, selectRegisterState } from "src/store/authentication";
import { useAppDispatch, useAppSelector } from "src/core/hooks";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import {
  Paper,
  NavLink,
  PasswordInput,
  TextInput,
  Title,
  Text,
  List,
  ThemeIcon,
  Flex,
  Box,
  Center,
  Checkbox,
  Anchor,
  Alert,
} from "@mantine/core";
import { PageRoute } from "src/utility/utils";
import { CircleIcon } from "src/ui/icons/cirlce-icon";
import { CircleCheckIcon } from "src/ui/icons/cirlce-check-icon";
import { CopyrightIcon } from "src/ui/icons/copyright-icon";
import { ToSModal, getTosVersion } from "src/ui/components/tos-modal";
import { login, selectLoginState } from "src/store/user";
import { DwellirLogo } from "src/ui/icons/dwellir-logo";

interface RegisterForm {
  email: string;
  password: string;
}

export const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showTosModal, setShowTosModal] = useState(false);
  const [tosAgreement, setTosAgreement] = useState(false);
  const [internalError, setInternalError] = useState<null | ReactNode>(null);

  const loginState = useAppSelector(selectLoginState);
  const registerState = useAppSelector(selectRegisterState);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = (values: RegisterForm) => {
    if (values.password) {
      dispatch(
        register({
          email: values.email,
          password: values.password,
          isActive: true,
          isSuperuser: false,
          isVerified: false,
          tosAgreement: getTosVersion(undefined), // There is no user when signing up
        }),
      );
    }
  };

  useEffect(() => {
    if (registerState.state === "fulfilled") {
      dispatch(
        login({ username: form.values.email, password: form.values.password }),
      ).then(() => {
        navigate(PageRoute.VERIFY);
      });
    }
  }, [
    registerState,
    navigate,
    form.values.email,
    form.values.password,
    dispatch,
  ]);

  useEffect(() => {
    if (registerState.state === "rejected") {
      const errors = form.errors;
      if (
        registerState.error.type === "password_too_short" &&
        !Object.keys(errors).includes("password")
      ) {
        form.setFieldError("password", t("register.password_too_short"));
      } else if (registerState.error.type === "disposable_domain_blocked") {
        form.setFieldError(
          "email",
          "Disposable email domains are not allowed.",
        );
      } else {
        setInternalError(
          <Text>
            Failed to register user, if you already have an account and forgot
            your password you can reset it{" "}
            <Anchor
              c="green"
              href={PageRoute.FORGOT_PASSWORD + `?email=${form.values.email}`}
              inherit
            >
              here
            </Anchor>
          </Text>,
        );
      }
    }
  }, [registerState, form, t]);

  return (
    <div className={classes.wrapper}>
      <ToSModal
        show={showTosModal}
        onCloseDisagree={() => {
          setShowTosModal(false);
          setTosAgreement(false);
        }}
        onCloseAccept={() => {
          setShowTosModal(false);
          setTosAgreement(true);
        }}
      />
      <Paper h="100vw" className={classes.form} radius={0} p={30}>
        <Flex h="100%" direction="column" justify="space-between">
          <Box>
            <Flex w="100%" justify="center">
              <DwellirLogo />
            </Flex>
            <Title
              order={2}
              className={classes.title}
              ta="center"
              mt="md"
              mb={50}
            >
              Register a new user
            </Title>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <TextInput
                mb="lg"
                label={t("register.email")}
                placeholder={t("register.email_placeholder")}
                {...form.getInputProps("email")}
              />
              <PasswordInput
                mb="xs"
                label={t("register.password")}
                placeholder={t("register.password_placeholder")}
                {...form.getInputProps("password")}
              />
              <Checkbox
                mb="md"
                checked={tosAgreement}
                onChange={(event) => {
                  setTosAgreement(event.currentTarget.checked);
                }}
                label={
                  <>
                    <Text>
                      I agree to Dwellir's{" "}
                      <Anchor
                        c="green"
                        onClick={(event) => {
                          event.preventDefault();
                          setShowTosModal(true);
                        }}
                        inherit
                      >
                        Terms of Service
                      </Anchor>
                    </Text>
                  </>
                }
              />
              <Text size="xs" mb="xs">
                Password must contain:
              </Text>
              <List
                spacing="xs"
                size="sm"
                mb="lg"
                center
                c={
                  form.values.password.length >= 12 && !form.errors.password
                    ? ""
                    : "grey"
                }
                icon={
                  <ThemeIcon color="white" size={16} radius="xl">
                    <CircleIcon />
                  </ThemeIcon>
                }
              >
                {form.values.password.length >= 12 && !form.errors.password ? (
                  <List.Item
                    icon={
                      <ThemeIcon size={16} radius="xl" color="white">
                        <CircleCheckIcon fillColor="green" />
                      </ThemeIcon>
                    }
                  >
                    12 characters
                  </List.Item>
                ) : (
                  <List.Item>12 characters</List.Item>
                )}
              </List>
              {internalError && (
                <Alert mt="md" radius="md" color="red">
                  {internalError}
                </Alert>
              )}
              <SubmitButton
                fullWidth
                mt="xl"
                size="md"
                disabled={tosAgreement === false}
                loading={loginState.state === "loading"}
                text={t("register.button.submit")}
              />
            </form>
          </Box>
          <Center>
            <NavLink
              w="auto"
              href="https://dwellir.com"
              label="Dwellir.com"
              variant="subtle"
              active
              color="green"
              leftSection={<CopyrightIcon />}
            />
          </Center>
        </Flex>
      </Paper>
    </div>
  );
};
